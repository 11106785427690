import api from "@/apiConfig/api";

export const postTutorBook = async ({
  selectedTutor,
  selectedDate,
  selectedTime,
  lessonTopic,
}) => {
  return await api.post("course_elements/extra_lessons", {
    tutor_id: selectedTutor,
    for_date: selectedDate,
    slot_id: selectedTime,
    theme: lessonTopic,
  });
};
