import api from "@/apiConfig/api";

export const postHWFiles = async (isResend, formData) => {
  if (isResend === false) {
    try {
      return await api.post("/course_elements/answer/", formData);
    } catch (error) {
      console.log("/course_elements/answer/", error);
      throw error;
    }
  } else {
    try {
      return await api.put("/course_elements/edit/answer/", formData);
    } catch (error) {
      console.log("/course_elements/edit/answer/", error);
      throw error;
    }
  }
};
