<template>
  <div class="bg-white rounded-[20px] text-center w-full pt-5 overflow-hidden">
    <el-skeleton animated class="w-full">
      <template #template>
        <el-skeleton-item
          variant="image"
          class="w-[200px] h-[200px] rounded-[50px] mx-auto"
        />
        <div>
          <el-skeleton-item variant="text" class="w-[250px] h-[30px] mt-2" />
        </div>
        <div>
          <el-skeleton-item variant="text" class="w-[250px] mt-2" />
          <el-skeleton-item variant="text" class="w-[250px] mt-2" />
        </div>
        <div class="p-5 flex justify-around">
          <el-skeleton-item variant="text" class="w-[150px] h-[40px]" />
          <el-skeleton-item variant="text" class="w-[150px] h-[40px]" />
        </div>
      </template>
    </el-skeleton>
  </div>
</template>
