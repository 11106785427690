<template>
  <link rel="stylesheet" href="./style/change-password.css">
  <div class="change-lang">
    
    <div class="change-lang__cross-btn"><h1 class="change-lang__cross-btn-h1">{{ $t("header.settings") }}</h1>
      <button
        @click="dialogVisible = false"
        class="change-lang__cross-btn-dialog-close"
      >
        <img
          class="change-lang__cross-btn-dialog-close_img"
          src="@/assets/close-icon.png"
          alt=""
        />
      </button>
    </div>

    <div class="change-lang__change-inp-place">
      <h6 class="change-lang__change-inp-place_lbl">Telefon Raqam</h6>
      <div class="">
        <input type="phone"  class="change-lang__change-inp-place_inp" placeholder="+998 | 994988189">
      <button
          class="change-lang__change-inp-place_btn"
          @click="hideSHowPass()"
          type="button"
        >
          Ozgartirish
        </button>
      </div>
      
    </div>
    <div class="change-lang__change-inp-place">
      <h6 class="change-lang__change-inp-place_lbl">Mahfiy kod</h6>
      <div class="">
        <input type="phone"  class="change-lang__change-inp-place_inp" placeholder="+998 | 994988189">
      <button
          class="change-lang__change-inp-place_btn"
          @click="hideSHowPass()"
          type="button"
        >
          Ozgartirish
        </button>
      </div>
      
      <a class="change-lang_a" href="#">Mahfiy kod esingizdan chiqdimi?</a>
    </div>
    
      <!-- <div class="change-lang__change-inp-place">
        <input
          :placeholder="$t('header.change-password')"
          :type="passType"
          class="change-lang__change-inp-place_inp"
        />
       
      </div> -->

      <!-- <div class="change-lang__footer">
        <button
          class="change-lang__footer-btn-confirm"
          type="primary"
          @click="dialogVisible = false"
        >
          {{ $t("header.modal.confirm_btn") }}
        </button>
        <button
          class="change-lang__footer-btn-cancel"
          @click="dialogVisible = false"
        >
          {{ $t("header.modal.cancel_btn") }}
        </button>
      </div> -->
    </div>
  
</template>

<script>
import "./style/change-password.css";

export default {
  data() {
    return {
      dialogVisible: false,
      passType: "password",
    };
  },
  methods: {
    hideSHowPass() {
      this.passType = this.passType === "password" ? "text" : "password";
    },
  },
};
</script>

