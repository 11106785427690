import apiV2 from "@/apiConfig/apiV2";

export const getStudentsRating = async ({ filter, from_date, till_date }) => {
  try {
    return await apiV2.get("/coins/student/ratings", {
      params: {
        filter,
        from_date,
        till_date,
      },
    });
  } catch (error) {
    console.log("/coins/student/ratings: " + error);
    return error;
  }
};
