<template>
  <el-skeleton animated>
    <template #template>
      <div class="flex items-center gap-5">
        <el-skeleton-item variant="circle" class="w-[64px] h-[64px]" />
        <div class="flex-1">
          <div class="flex items-center justify-between flex-1">
            <el-skeleton-item variant="text" class="w-[80px]" />
          </div>
          <div class="mt-2">
            <el-skeleton-item variant="text" />
            <el-skeleton-item variant="text" />
          </div>
        </div>
      </div>
    </template>
  </el-skeleton>
</template>
