import api from "@/apiConfig/api";

export const postExamFiles = async ({ group_id, theme_id, link }, file) => {
  try {
    return await api.post("/courses/student_exam_answer", file, {
      params: {
        group_id,
        theme_id,
        link,
      },
    });
  } catch (error) {
    console.log("/courses/student_exam_answer", error);
    throw error;
  }
};
