import api from "@/apiConfig/api";

export const markAnswer = async (answer_id, mark) => {
  return await api.post("/course_elements/extra_lessons/mark", {
    id: answer_id,
    mark: mark,
    comment: "Lesson level is " + mark,
  });
};
export const cancelBooking = async (id, status) => {
  return await api.patch("/course_elements/extra_lessons", {
    id,
    status,
  });
};
