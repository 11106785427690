import api from "@/apiConfig/api";

export const getTutorSlot = async (user_id, for_date) => {
  return await api.get("users/user_slots", {
    params: {
      user_id,
      for_date
    },
  });
};
