<template>
  <main>
    <StudentRating />
  </main>
</template>

<script>
import { StudentRating } from "@/widgets";

export default {
  components: {
    StudentRating,
  },
};
</script>

<style></style>
