<template>
  <div class="container">
    <div v-if="showAlert && !isClosedAlert && currentDate">
      <div v-if="currentDate?.day > 5">
        <div class="payment-alert-warning">
          <i class="fa-solid fa-circle-info"></i>
          <p v-if="months[paymentForMonth]">
            <span v-if="lang === 'uz'">
              {{ months[paymentForMonth][lang] }} oyi uchun to'lov
              yaqinlashmoqda. To'lov qilishni unutmang!
            </span>
            <span v-else>
              Приближается оплата за {{ months[paymentForMonth][lang] }}. Не
              забудьте заплатить!
            </span>
          </p>
          <button @click="isClosedAlert = true">
            <i class="fa-solid fa-circle-xmark"></i>
          </button>
        </div>
      </div>
      <div v-else-if="studentInfo?.balance < 0">
        <div class="payment-alert-error">
          <i class="fa-solid fa-circle-info"></i>
          <p v-if="months[paymentForMonth]">
            <span v-if="lang === 'uz'">
              Space hisobingiz yopilishiga {{ 6 - currentDate?.day }} kun qoldi,
              to’lovni amalga oshiring. Qarzdorlik -
              {{ numeral(studentInfo?.balance).format("0,0[00]") }} so'm
            </span>
            <span v-else>
              До закрытия вашего аккаунта Space осталось
              {{ 6 - currentDate?.day }} дней, внесите оплату. Задолженность -
              {{ numeral(studentInfo?.balance).format("0,0[00]") }} сум
            </span>
          </p>
          <button @click="isClosedAlert = true">
            <i class="fa-solid fa-circle-xmark"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import axios from "axios";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import numeral from "numeral";

const currentDate = ref(null);
const isClosedAlert = ref(false);
const store = useStore();

const studentInfo = computed(() => store.state.student?.studentInfo);

const months = {
  1: {
    uz: "Yanvar",
    ru: "Январь",
  },
  2: {
    uz: "Fevral",
    ru: "Февраль",
  },
  3: {
    uz: "Mart",
    ru: "Март",
  },
  4: {
    uz: "Aprel",
    ru: "Апрель",
  },
  5: {
    uz: "May",
    ru: "Май",
  },
  6: {
    uz: "Iyun",
    ru: "Июнь",
  },
  7: {
    uz: "Iyul",
    ru: "Июль",
  },
  8: {
    uz: "Avgust",
    ru: "Август",
  },
  9: {
    uz: "Sentyabr",
    ru: "Сентябрь",
  },
  10: {
    uz: "Oktyabr",
    ru: "Октябрь",
  },
  11: {
    uz: "Noyabr",
    ru: "Ноябрь",
  },
  12: {
    uz: "Dekabr",
    ru: "Декабрь",
  },
};

const lang = localStorage.getItem("lang") || "uz";

const paymentForMonth = computed(() => {
  if (currentDate.value?.day > 5) {
    return currentDate.value.plus({ month: 1 })?.month;
  } else {
    return currentDate.value?.month;
  }
});

const showAlert = computed(() => {
  const day = currentDate.value?.day || 0;
  const lastDayOfMonth = currentDate.value?.endOf("month").day || 30;
  return day > lastDayOfMonth - 5 || day <= 5;
});

onMounted(async () => {
  currentDate.value = await axios
    .get("https://api.marsit.uz/now/datetime")
    .then((res) => DateTime.fromISO(res.data)?.setLocale("en"));
});
</script>

<style lang="scss">
.payment-alert {
  &-warning {
    background-color: #fffbe6;
    padding: 8px 16px;
    border-radius: 5px;
    border: 0.6px solid #e3c79f;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    p {
      color: #cc9300;
      font-size: 14px;
      font-weight: 500;
    }
    i {
      color: #cc9300;
    }
    button {
      display: block;
      margin-left: auto;
      background-color: transparent;
      border: none;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
  &-error {
    background-color: #ffeee9;
    padding: 8px 16px;
    border-radius: 5px;
    border: 0.6px solid #ea1e61;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    p {
      color: #ea1e61;
      font-size: 14px;
      font-weight: 500;
    }
    i {
      color: #ea1e61;
    }
    button {
      display: block;
      margin-left: auto;
      background-color: transparent;
      border: none;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}
</style>
