<template>
  <div
    class="book-tutor"
    :style="{
      opacity: lesson?.data[0]?.status === 'canceled' ? 0.5 : 1,
    }"
  >
    <img
      class="book-tutor__img"
      v-if="lesson?.data[0]?.tutor?.profile?.avatar"
      :src="mediaLink + lesson?.data[0]?.tutor?.profile?.avatar"
      alt=""
    />
    <img
      v-else
      class="book-tutor__img"
      src="@/assets/tutor-img.png"
      alt="tutor-image"
    />

    <div class="book-tutor__text-wrapper">
      <p class="book-tutor__heading">
        {{
          lesson?.data
            ? lesson?.data[0]?.tutor?.first_name +
              " " +
              lesson?.data[0]?.tutor?.last_name
            : $t("main.booking.heading")
        }}
      </p>
      <p class="book-tutor__title">
        {{
          lesson?.data
            ? lesson?.for_date +
              " | " +
              lesson?.data[0].slot?.from_hour?.slice(0, 5)
            : $t("main.booking.title")
        }}
      </p>
      <p class="book-tutor__title">
        {{ lesson?.data[0]?.theme }}
      </p>
      <div class="book-tutor__mark" v-if="lesson?.data[0].status === 'came'">
        <div v-for="i in 5" :key="i">
          <el-icon
            size="24"
            :color="
              i <= lesson?.data[0]?.mark || i <= lessonLevel
                ? '#FACC15'
                : '#999'
            "
            :style="{ cursor: !lesson?.data[0].mark ? 'pointer' : 'auto' }"
            @click="
              !lesson?.data[0].mark ? (lessonLevel = i) : lesson?.data[0].mark
            "
            ><StarFilled
          /></el-icon>
        </div>
      </div>
    </div>
    <button
      v-if="!lesson?.data && studentInfo?.balance >= 0"
      class="book-tutor__btn"
      @click="bookingModal = true"
    >
      {{ $t("main.booking.btn.register")}}
    </button>
    <button
      v-else-if="studentInfo?.balance < 0"
      class="book-tutor__btn unable-btn"
    >
      <img src="@/assets/locked-icon.png" alt="locked">
      {{ $t("main.booking.btn.register")}}
    </button>
    <button
      class="book-tutor__btn cancel-btn"
      v-else-if="lesson?.data[0].status === 'not_came'"
      @click="cancelLesson"
    >
    {{ $t("main.booking.btn.cancel")}}
    </button>
    <button
      class="book-tutor__btn marked-btn"
      v-else-if="lesson?.data[0].mark && lesson?.data[0].status === 'came'"
      style="cursor: auto"
    >
    {{ $t("main.booking.btn.estimated")}}
    </button>
    <button
      @click="markLesson"
      class="book-tutor__btn mark-btn"
      v-else-if="lesson?.data[0].status === 'came'"
    >
    {{ $t("main.booking.btn.estimate")}}
    </button>

    <button
      class="book-tutor__btn cancel-btn"
      v-else-if="lesson?.data[0].status === 'canceled'"
      style="cursor: auto"
    >
    {{ $t("main.booking.btn.canceled")}}
    </button>

    <!-- <div class="has-come" v-else>
      <el-icon><CircleCheck color="#009689" /></el-icon>
    </div> -->
  </div>
  <el-dialog
    align-center
    v-model="bookingModal"
    class="book-tutor-modal__wrapper"
  >
    <book-tutor
      :company_id="company_id"
      @close-modal="bookingModal = false"
      :localTime="localTime"
      @bookCreated="(bookingModal = false), getExtraLessons()"
    />
  </el-dialog>
</template>

<script>
import { BookTutor } from "@/features";
import { getBookInfo } from "./api/get-book-info.api";
import { markAnswer, cancelBooking } from "./api/mark-lesson.api";
import "./style/book-tutor.css";
import { onBeforeMount, ref, watch } from "vue";
import { DateTime } from "luxon";
import { getLocalTime } from "@/service/get-localtime.api";
import { StarFilled } from "@element-plus/icons-vue";
import { mediaLink } from "@/apiConfig/mediaLink";
import { useStore } from "vuex";

export default {
  components: { BookTutor, StarFilled },
  setup() {
    const bookingModal = ref(false);
    const store = useStore();

    const date = DateTime;

    const from_date = ref(null);
    const till_date = ref(null);

    const id = ref(null);
    const company_id = ref(null);

    const bookedLesson = ref(null);
    const localTime = ref(null);
    const lesson = ref(null);

    const studentInfo = ref(store.state.student.studentInfo);

    watch(() => store.state.student.studentInfo, (newValue) => {
      studentInfo.value = newValue;
      if (newValue) {
        id.value = newValue.id;
        company_id.value = newValue.company_id;
        updateDates();
        getExtraLessons();
      }
    });

    const updateDates = () => {
      from_date.value = date.fromISO(localTime.value).toFormat("yyyy-MM-dd");
      till_date.value = date.fromISO(localTime.value).plus({ days: 7 }).toFormat("yyyy-MM-dd");
    };

    const getExtraLessons = async () => {
      if (!id.value || !from_date.value || !till_date.value) return;
      bookedLesson.value = await getBookInfo(
        from_date.value,
        till_date.value,
        id.value
      )
        .then((res) => {
          return res.data.filter((lesson) => lesson?.data?.length);
        })
        .catch(() => []);

      lesson.value = bookedLesson.value[0];
    };

    onBeforeMount(async () => {
      localTime.value = await getLocalTime().then((res) => res.data);
      updateDates();

      if (studentInfo.value) {
        id.value = studentInfo.value.id;
        company_id.value = studentInfo.value.company_id;
        await getExtraLessons();
      }
    });
    const lessonLevel = ref(null);

    const markLesson = async () => {
      await markAnswer(lesson.value.data[0].id, lessonLevel.value).then(() => {
        getExtraLessons();
      });
    };

    const cancelLesson = async () => {
      await cancelBooking(lesson.value.data[0].id, "canceled").then(() => {
        getExtraLessons();
      });
    };
    return {
      bookingModal,
      bookedLesson,
      date,
      localTime,
      getExtraLessons,
      lesson,
      markLesson,
      lessonLevel,
      cancelLesson,
      mediaLink,
      company_id,
      studentInfo,
    };
  },
};
</script>

<style></style>
