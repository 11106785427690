import api from "@/apiConfig/api";

export const getTutorsList = async (role, branch_id) => {
  return await api.get("users", {
    params: {
      role,
      branch_id,
    },
  });
};
