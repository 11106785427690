<template>
  <main>
    <div class="news-info">
      <div
        class="news-info__main container news-container"
        v-if="getNews?.length"
      >
        <div class="news-info__main__card__text">
          <h3 class="news-info__main__card__text-title">
            {{ getNews[0].title?.ru }}
          </h3>
          <p class="news-info__main__card__text-time">
            {{
              DateTime.fromISO(getNews[0].date_time).toLocaleString(
                DateTime.DATETIME_MED
              )
            }}
          </p>
        </div>
        <div class="news-info__main__card">
          <img :src="mediaLink + getNews[0].image" alt="news-photo" />
        </div>
        <div class="news-info__main__descr">
          <p class="news-info__main__text">
            {{ getNews[0].detail.description?.ru }}
          </p>
        </div>
        <!-- <div class="news-info__main__photo">
          <h2 class="news-info__main__heading">Немного фотографий с события</h2>
          <swiper
            class="swiper"
            :modules="modules"
            :space-between="66"
            :slides-per-view="mobile ? 1 : 3"
            :pagination="{ clickable: true }"
          >
            <swiper-slide v-for="item in 3" :key="item">
              <img src="../assets/course-default.png" alt="" />
            </swiper-slide>
          </swiper>
        </div> -->
      </div>
    </div>
  </main>
</template>

<script>
import { Pagination } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
import { getNewsDescr } from "@/service/news.service";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import { mediaLink } from "@/apiConfig/mediaLink";

export default {
  components: {
    // Swiper,
    // SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination],
      news: [],
      getNews: null,
      newsId: Number(this.$route.params.id),
      DateTime: DateTime,
      store: useStore(),
      mediaLink: mediaLink,
    };
  },
  async beforeMount() {
    this.news = await getNewsDescr()
      .then((res) => res.data)
      .catch((e) => {
        if (e.response.status === 403) {
          this.store.commit("auth/logout");
        }
      });
    await this.filterNewsById();
  },
  methods: {
    async filterNewsById() {
      this.getNews = this.news.filter((item) => item.id === this.newsId);
    },
  },
};
</script>

<style lang="scss">
.news-container {
  width: 826px;
  margin: 0 auto;
}
.news-info {
  margin-bottom: 30px;
  &__header {
    padding: 34px 60px;
    &-btn {
      padding-left: 44px;
      position: relative;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: #0e0d5d;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        width: 28px;
        height: 28px;
        background-image: url("../assets/arrow-back.svg");
        background-repeat: no-repeat;
      }
    }
  }
  &__main {
    margin-top: 29px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    &__card {
      width: 826px;
      border-radius: 32px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 32px;
      }

      &__text {
        color: #0e0d5d;
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        &-time {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: 0.2px;
        }

        &-title {
          font-weight: 700;
          font-size: 24px;
          line-height: 120%;
        }
      }
    }
    &__heading {
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      color: #0e0d5d;
      margin-bottom: 24px;
    }
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.2px;
      color: #424242;
    }
  }
}
</style>
