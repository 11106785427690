<template>
  <div class="ans-and-ques">
    <div class="h-screen fixed top-0 left-0 bg-[#00000057] w-screen">
      <div class="flex w-full h-screen items-center justify-center">
        <div class="w-[400px] bg-white rounded-xl p-5 h-auto">
          <p class="text-center text-xl font-bold text-titleBlue">
            {{ $t("set-full-name") }}
          </p>
          <form action="" class="mt-5" @submit.prevent="setNameFunc">
            <label for="firstname" class="text-sm font-medium"
              ><span class="text-red-400">*</span>
              {{ $t("signup.first_name") }}</label
            >

            <input
              type="text"
              :placeholder="$t('signup.first_name')"
              class="w-full bg-[#fafafa] p-2 border border-solid border-grayScale300 rounded-md"
              id="firstname"
              v-model="firstName"
            />
            <label for="lastname" class="text-sm font-medium mt-5 inline-block"
              ><span class="text-red-400">*</span>
              {{ $t("signup.last_name") }}</label
            >

            <input
              id="lastname"
              type="text"
              :placeholder="$t('signup.last_name')"
              class="w-full bg-[#fafafa] p-2 border border-solid border-grayScale300 rounded-md"
              v-model="lastName"
            />

            <button
              type="submit"
              :disabled="!firstName.length || !lastName.length"
              class="mt-5 w-full p-3 rounded-md bg-primary500 text-white font-bold disabled:opacity-30"
            >
              {{ $t("signup.confirm") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import api from "@/apiConfig/api";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";

const props = defineProps(["student"]);
const store = useStore();

const firstName = ref("");
const lastName = ref("");

function setNameFunc() {
  const std = props.student;
  std.first_name = firstName.value;
  std.last_name = lastName.value;
  std.status_type = "student attended in class";

  editStd(std.id, std);
}

function editStd(id, std) {
  api
    .patch(`/leads/${id}`, std, {
      params: {
        id: id,
      },
    })
    .then(() => {
      store.dispatch("getMe");
      ElMessage.success("success");
    })
    .catch((err) => {
      ElMessage.error(err);
    });
}
</script>
