<template>
  <div
    :class="['lesson', 'lesson-wrapper', `lesson--${status}`]"
    @click="goToHWPage"
    :disabled="status === 'closed'"
  >
    <div class="lesson-left">
      <div class="lesson__order">
        <span>{{ order < 10 ? "0" : "" }}{{ order }}</span>
      </div>
    </div>
    <div class="lesson-center">
      <p class="lesson__status" v-if="status !== 'closed'">
        {{ $t(`main.tasks.${status}`) }}
      </p>
      <h4 class="lesson__name">
        {{
          name && name?.length < 20 ? `${name}` : `${name?.substring(0, 20)}...`
        }}
      </h4>
      <p class="lesson__descr" v-if="status !== 'marked'">
        {{
          description && description?.length < 50
            ? `${description}`
            : `${description?.substring(0, 50)}...`
        }}
      </p>
      <p class="lesson__mark" v-if="status !== 'closed' && status === 'marked'">
        Оценка: <span> {{ mark }}</span>
      </p>
    </div>
    <div class="lesson-right">
      <p class="lesson__status-icon"></p>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import "./style/lesson.css";

export default {
  props: {
    status: {
      type: String,
      required: true,
      default: "closed",
    },
    name: {
      type: String,
      required: true,
      default: "Lesson name",
    },
    description: {
      type: String,
      required: true,
      default: "Lesson description",
    },
    order: {
      type: Number,
      required: true,
      default: 1,
    },
    mark: {
      type: Number,
      required: true,
      default: 20,
    },
    groupId: {
      type: Number,
      required: true,
      default: undefined,
    },
    taskId: {
      type: Number,
      required: true,
      default: undefined,
    },
    courseId: {
      type: Number,
      required: true,
      default: undefined,
    },
    isExam: {
      type: Number,
      required: true,
      default: 0,
    },
    studentProfile: {
      type:Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const router = useRouter();

    const goToHWPage = () => {
      if (props.status !== "closed" && !props.studentProfile) {
        router.push({
          name: "homework",
          query: {
            group: props.groupId,
            course: props.courseId,
            lesson: props.isExam,
            task: props.taskId,
          },
        });
      }
    };

    return {
      goToHWPage,
    };
  },
};
</script>

<style></style>
