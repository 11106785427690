<template>
  <div class="news-frame">
    <div class="news-frame-left">
      <img class="news-frame-img" src="@/assets/news.svg" alt="" />

      <div>
        <p class="news-frame-title">{{  $t("main.news-frame.heading") }}</p>
        <p class="news-frame-text">
          {{  $t("main.news-frame.title") }}
        </p>
      </div>
    </div>
    <div class="news-frame-right">
      <router-link to="/mars-students-news">
        <button class="news-frame-btn">
          Watch
          <img src="@/assets/news-play-icon.svg" alt="" />
        </button>
      </router-link>
    </div>
  </div>
</template>
<style lang="scss">
@import "./style/news-frame.scss";
</style>
