<template>
  <span
    class="mark"
    :class="{
      max: mark >= 90,
      medium: mark < 90 && mark >= 70,
      min: mark < 70 && mark >= 60,
      zero: mark > 0 && mark < 60,
    }"
  >
    {{ mark || "-" }}
  </span>
</template>
<script>
export default {
  props: ["mark"],
};
</script>
<style>
.mark {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 50px;
  padding: 5px;
  border-radius: 5px;
}
.mark.max {
  background-color: #009689;
  color: #fff;
}
.mark.medium {
  background-color: #facc15;
  color: #fff;
}
.mark.min {
  background-color: #00a9f1;
  color: #fff;
}
.mark.zero {
  background-color: #ea1e61;
  color: #fff;
}
</style>
