<template>
  <div class="create-post">
    <!-- Header -->
    <div class="create-post__header">
      <div class="create-post__header-left">
        <img :src="mediaLink + studentInfo?.avatar" alt="" />
      </div>
      <div class="creae-post__header-right">
        <h1>{{ studentInfo?.first_name + " " + studentInfo?.last_name }}</h1>
      </div>
    </div>
    <!-- Body -->
    <div class="create-post__body">
      <el-input
        v-model="description"
        style="width: 100%"
        autosize
        type="textarea"
        :placeholder="$t('header.header-create-post.post-inp')"
      />

      <div class="image-preview" v-if="uploadedImg.length > 0">
        <div v-for="img in uploadedImg" :key="img" class="wrapper-swiper">
          <img :src="img" alt="Image Preview" class="preview-image" />
        </div>

        <div class="times-btn">
          <button @click="clearData">
            <el-icon size="large" color="white"><CircleCloseFilled /></el-icon>
          </button>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <div class="create-post__footer">
      <el-upload
        drag
        accept=".png, .jpg, .jpeg"
        :auto-upload="false"
        :limit="1"
        v-model:file-list="fileList"
        @change="handleFileChange"
        class="upload-demo"
      >
        <button class="upload-image" v-if="fileList?.length < 1">
          <img src="@/assets/upload-img.svg" alt="Upload" />
        </button>
      </el-upload>
      <div>
        <button
          class="send"
          @click="createPost"
          :disabled="!description || uploadedImg.length === 0"
        >
          Post
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "./style/style.css";
import { ref, watch } from "vue";
import { CircleCloseFilled } from "@element-plus/icons-vue";
import { postPost } from "./api/create-post";
import { mediaLink } from "@/apiConfig/mediaLink";
import { useStore } from "vuex";

export default {
  components: {
    CircleCloseFilled,
  },
  setup(_, { emit }) {
    const store = useStore();
    const studentInfo = store.state.student.studentInfo;
    const description = ref("");
    const fileList = ref([]);
    const uploadImg = ref(false);
    const uploadedImg = ref([]);

    const activeGroup = ref(null);

    watch(
      () => store.state.student.studentInfo,
      (newValue) => {
        studentInfo.value = newValue;
        if (studentInfo.value) {
          activeGroup.value = studentInfo.value?.groups?.find(
            (group) => group.status === 5
          );
        }
      }
    );

    // Ensure removeImages is called in the template
    const removeImages = () => {
      uploadedImg.value = [];
      fileList.value = [];
    };

    const handleFileChange = async (file, fileList) => {
      uploadedImg.value = [];
      if (fileList && fileList.length > 0) {
        for (const file of fileList) {
          const rawFile = file?.raw || file;
          if (rawFile && (rawFile instanceof File || rawFile instanceof Blob)) {
            uploadedImg.value.push(URL.createObjectURL(rawFile));
          } else {
            console.error("Invalid file selected:", rawFile);
          }
        }
      } else {
        uploadedImg.value = [];
      }
    };

    const createPost = async () => {
      if (uploadedImg.value.length < 1) {
        console.error("Minimal 1 ta rasim yuklashingiz kerak.");
        return;
      }

      const formData = new FormData();
      for (const img of uploadedImg.value) {
        const response = await fetch(img);
        const blob = await response.blob();
        formData.append("files", blob);
      }
      await postPost(formData, description.value);
      emit("close");
      setTimeout(() => {
        clearData();
        window.location.reload();
      }, 500);
    };

    const clearData = () => {
      description.value = "";
      fileList.value = [];
      uploadedImg.value = [];
      uploadImg.value = false;
    };

    return {
      description,
      fileList,
      uploadImg,
      uploadedImg,
      clearData,
      createPost,
      removeImages, // Make sure removeImages is returned here
      studentInfo,
      activeGroup,
      mediaLink,
      handleFileChange,
    };
  },
};
</script>

<style>
.el-upload-dragger {
  border: 0 !important;
}
.image-preview {
  position: relative;
  margin-top: 20px;
}
.preview-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}
.upload-wrapper-box {
  margin-top: 20px;
  position: relative;
}

.el-upload__text {
  margin-top: 10px;
  font-size: 14px;
  color: #606266;
}
.times-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.create-post__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-image {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
