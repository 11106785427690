import { getStudentInfo } from "@/service/get-student-info.service";

export default {
  state() {
    return {
      studentInfo: {},
      module: 1,
      examDate: "",
      currentDate: "",
      groupId: null,
      groupName: "",
      courseId: null,
      elementId: null,
      hwUploaded: false,
    };
  },
  actions: {
    async getMe(ctx) {
      ctx.state.studentInfo = await getStudentInfo()
        .then((res) => res.data)
        .catch(() => {});
    },
    setModule(ctx, module) {
      ctx.state.module = module;
    },
    setExamDate(ctx, date) {
      ctx.state.examDate = date;
    },
    setCurrentDate(ctx, date) {
      ctx.state.currentDate = date;
    },
    setGroupId(ctx, id) {
      ctx.state.groupId = id;
    },
    setCourseId(ctx, id) {
      ctx.state.courseId = id;
    },
    setGroupName(ctx, name) {
      ctx.state.groupName = name;
    },
    setElementId(ctx, id) {
      ctx.state.elementId = id;
    },
    setHwUploaded(ctx) {
      ctx.state.hwUploaded = !ctx.state.hwUploaded;
    },
  },
};
