<template>
  <div class="bg-white p-2 rounded-[10px] mb-5">
    <div class="flex items-center gap-2">
      <el-skeleton
        class="w-[50px]"
        animated
        style="--el-skeleton-circle-size: 50px"
      >
        <template #template>
          <el-skeleton-item variant="circle" />
        </template>
      </el-skeleton>
      <el-skeleton class="w-[150px]" :count="2">
        <template #template>
          <el-skeleton-item variant="text" />
        </template>
      </el-skeleton>
    </div>
    <el-skeleton animated class="my-2">
      <template #template>
        <el-skeleton-item variant="text" />
      </template>
    </el-skeleton>
    <el-skeleton animated class="my-2">
      <template #template>
        <el-skeleton-item variant="image" class="w-full h-[200px]" />
        <div class="flex gap-2 mt-2">
          <el-skeleton-item variant="text" class="w-[150px] h-[30px]" />
          <el-skeleton-item variant="text" class="w-[150px] h-[30px]" />
        </div>
        <div class="flex gap-2 mt-2">
          <el-skeleton-item variant="text" class="flex-1 h-[40px]" />
          <el-skeleton-item variant="text" class="w-[120px] h-[40px]" />
        </div>
      </template>
    </el-skeleton>
  </div>
</template>
