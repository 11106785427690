<template>
  <section class="rating">
    <StudentRatingFilter
      @ratingTimeChanged="ratingTime = $event.time"
      @ratingTypeChanged="ratingType = $event.type"
    />
    <div class="rating-top-animation">
      <div class="container">
        <div class="rating-top-animation-left" v-if="ratingTime">
          <h2 class="rating-top-animation__heading">
            {{ $t(`main.rating.sort.${ratingTime}`) }}
          </h2>
          <p class="rating-top-animation__text">
            {{ $t(`main.rating.text.${ratingTime}`) }}
          </p>
        </div>
        <div class="rating-top-animation-right">
          <swiper
            :loop="true"
            :autoplay="{
              delay: 4000,
              disableOnInteraction: false,
            }"
            :modules="ratingSwiperModule"
            v-if="top3Students?.length"
          >
            <swiper-slide
              v-for="(student, i) in top3Students?.slice(0, 3)"
              :key="student?.id"
            >
              <StudentMiniCard
                :position="i + 1"
                :studentGroup="student?.groups"
                :studentCoins="student?.coins"
                :studentName="`${student?.first_name} ${student?.last_name}`"
                :avatarLink="student?.avatar"
                :modmeId="student?.external_id"
                :borderImgLink="student?.rank?.image"
              />
            </swiper-slide>
          </swiper>
          <div v-else class="rating-animation__empty">
            <p class="rating-animation__empty__text">
              {{ $t("main.rating.no-data") }}
            </p>
          </div>
          <div class="rating-top-animation__img-wrapper">
            <div
              class="rating-top-animation__imgs"
              v-for="item in 5"
              :key="item"
            >
              <template v-for="imgs in 2" :key="imgs">
                <img src=@/assets/logos/emoji/emoji1.png alt="" /> <img
                src=@/assets/logos/emoji/emoji2.png alt="" /> <img
                src=@/assets/logos/emoji/emoji3.png alt="" /> <img
                src=@/assets/logos/emoji/emoji4.png alt="" /> <img
                src=@/assets/logos/emoji/emoji5.png alt="" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rating__students" v-if="!loading">
      <div class="container">
        <template v-if="clansRating !== null">
          <p v-for="clan in Object.keys(clansRating)" :key="clan"></p>
          <div
            class="rating__students__clan-wrapper"
            v-for="clan in Object.keys(clansRating)"
            :key="clan"
          >
            <h3
              :class="`rating__students__clan-heading rating__students__clan-heading--${clan}`"
              rating__students__clan-heading
            >
              {{ $t(`ligas.${clan}`) }}
            </h3>
            <ul class="rating__students__list">
              <li v-for="student in clansRating[clan]" :key="student?.id">
                <StudentMiniCard
                  :studentGroup="student?.groups"
                  :studentCoins="student?.coins"
                  :studentName="`${student?.first_name} ${student?.last_name}`"
                  :avatarLink="student?.avatar"
                  :borderImgLink="student?.rank?.image"
                  :order="student?.order"
                  :modmeId="student?.external_id"
                  :isMe="student?.me"
                  :showProfile="true"
                  :id="student?.id"
                />
              </li>
            </ul>
          </div>
        </template>
        <ul
          v-if="groupOrAll?.length"
          class="rating__students__list rating__students__list--big"
        >
          <li v-for="student in groupOrAll" :key="student?.id">
            <StudentMiniCard
              :studentGroup="student?.groups"
              :studentCoins="student?.coins"
              :studentName="`${student?.first_name} ${student?.last_name}`"
              :avatarLink="student?.avatar"
              :borderImgLink="student?.rank?.image"
              :order="student?.order"
              :modmeId="student?.external_id"
              :isMe="student?.me"
              :showProfile="true"
              :id="student?.id"
            />
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="rating__no-data">
      <el-skeleton
        style="--el-skeleton-circle-size: 100px"
        animated
        class="container"
      >
        <template #template>
          <el-skeleton-item variant="button" v-for="item in 6" :key="item" />
        </template>
      </el-skeleton>
    </div>
  </section>
</template>

<script>
import { StudentRatingFilter } from "@/features";
import { getStudentsRating } from "./api/get-students-rating.api";
import { computed, ref, watch } from "vue";
import { DateTime } from "luxon";
import { Swiper } from "vue-awesome-swiper";
import { Autoplay } from "swiper";
import { StudentMiniCard } from "@/entities";
import "./style/student-rating.css";

export default {
  components: {
    StudentRatingFilter,
    Swiper,
    StudentMiniCard,
  },
  setup() {
    const ratingType = ref(null);

    const ratingTime = ref("");

    const clansRating = ref({});

    const groupOrAll = ref(null);

    const studentsForTop = ref(null);

    const loading = ref(false);

    const changeRatingList = async (type, time) => {
      const { from_date, till_date } = time;
      const data = {
        from_date,
        till_date,
        filter: type,
      };
      if (from_date && till_date) {
        const res = await getStudentsRating(data);
        if (type === 2) {
          groupOrAll.value = null;
          // clansRating.value = res.data;
          for (const key in res.data) {
            if (res.data[key]?.students?.length > 7) {
              const myIndex = res.data[key]?.students?.findIndex(
                (student) => student?.me === true
              );
              if (myIndex > 7) {
                clansRating.value[key] = [
                  ...res.data[key]?.students?.slice(0, 3),
                  ...res.data[key]?.students?.slice(myIndex - 1, 3),
                ];
              } else {
                clansRating.value[key] = res.data[key]?.students?.slice(0, 7);
              }
            } else {
              clansRating.value[key] = res.data[key]?.students;
            }
          }
          studentsForTop.value = [
            ...res.data?.coders?.students,
            ...res.data?.gamers?.students,
            ...res.data?.hackers?.students,
          ];
        } else {
          clansRating.value = {};
          // groupOrAll.value = res.data;
          const myIndex = res.data?.findIndex(
            (student) => student?.me === true
          );
          if (myIndex > 7) {
            groupOrAll.value = [
              ...res.data?.slice(0, 3),
              ...res.data?.slice(myIndex - 1, 3),
            ];
          } else {
            groupOrAll.value = res.data?.slice(0, 7);
          }
          studentsForTop.value = res.data;
        }
      }
    };

    watch(ratingTime, () => {
      loading.value = true;
      if (ratingType.value !== null && ratingTime.value !== null) {
        changeRatingList(ratingType.value, calculateTime.value).finally(
          () => (loading.value = false)
        );
      }
    });

    watch(ratingType, () => {
      loading.value = true;
      if (ratingType.value !== null && ratingTime.value !== null) {
        changeRatingList(ratingType.value, calculateTime.value).finally(
          () => (loading.value = false)
        );
      }
    });

    const calculateTime = computed(() => {
      const time = {};
      if (ratingTime.value === "daily") {
        time["from_date"] = DateTime.now().toFormat("yyyy-MM-dd");
        time["till_date"] = DateTime.now().toFormat("yyyy-MM-dd");
      } else if (ratingTime.value === "weekly") {
        time["from_date"] = DateTime.now()
          .startOf("week")
          .toFormat("yyyy-MM-dd");
        time["till_date"] = DateTime.now().endOf("week").toFormat("yyyy-MM-dd");
      } else if (ratingTime.value === "monthly") {
        time["from_date"] = DateTime.now()
          .startOf("month")
          .toFormat("yyyy-MM-dd");
        time["till_date"] = DateTime.now()
          .endOf("month")
          .toFormat("yyyy-MM-dd");
      }
      return time;
    });

    const top3Students = computed(() => {
      if (studentsForTop.value?.length) {
        return studentsForTop.value?.slice().sort((studentA, studentB) => {
          return studentB.coins - studentA.coins;
        });
      } else {
        return [];
      }
    });

    return {
      ratingType,
      ratingTime,
      top3Students,
      ratingSwiperModule: [Autoplay],
      loading,
      clansRating,
      groupOrAll,
    };
  },
};
</script>