<template>
  <div class="rating-filter">
    <div class="container">
      <h2 class="rating-filter__heading">{{ $t("main.rating.heading") }}</h2>
      <div class="rating-filter__tab" v-if="screenWidth > 500">
        <el-tabs v-model="ratingType" class="demo-tabs">
          <el-tab-pane :label="$t('main.rating.tabs.clans')" name="clans" />
          <el-tab-pane
            :label="$t('main.rating.tabs.group')"
            name="group"
            v-if="studentGroups?.length"
          />
          <el-tab-pane :label="$t('main.rating.tabs.all')" name="all" />
        </el-tabs>
      </div>
      <div class="rating-filter__time">
        <el-select v-model="ratingType" v-if="screenWidth < 500">
          <el-option
            v-for="ratingtype in ratingTypeList"
            :key="ratingtype.value"
            :value="ratingtype.value"
            :label="ratingtype.label"
          />
        </el-select>
        <el-select v-model="ratingTime">
          <el-option
            v-for="time in ratingTimeList"
            :key="time.value"
            :value="time.value"
            :label="time.label"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import "./style/rating-filter.css";
import { useStore } from "vuex";

export default {
  emits: ["ratingTypeChanged", "ratingTimeChanged"],
  setup(_, { emit }) {
    const store = useStore();
    const ratingType = ref("");
    const studentGroups = computed(
      () => store.state.student.studentInfo.groups
    );

    watch(ratingType, () => {
      const type = {
        all: 0,
        group: 1,
        clans: 2,
      };
      emit("ratingTypeChanged", {
        type: type[ratingType.value],
      });
    });

    const { t } = useI18n();

    const ratingTime = ref("");

    watch(ratingTime, () => {
      emit("ratingTimeChanged", {
        time: ratingTime.value,
      });
    });

    const ratingTimeList = [
      {
        value: "daily",
        label: t("main.rating.sort.daily"),
      },
      {
        value: "weekly",
        label: t("main.rating.sort.weekly"),
      },
      {
        value: "monthly",
        label: t("main.rating.sort.monthly"),
      },
    ];
    const ratingTypeList = [
      {
        value: "all",
        label: t("main.rating.tabs.all"),
      },
      {
        value: "clans",
        label: t("main.rating.tabs.clans"),
      },
      {
        value: "group",
        label: t("main.rating.tabs.group"),
      },
    ];

    onBeforeMount(() => {
      ratingTime.value = "monthly";
      ratingType.value = "group";
    });

    const screenWidth = computed(() => {
      return window.innerWidth;
    });

    return {
      ratingType,
      ratingTime,
      ratingTimeList,
      screenWidth,
      ratingTypeList,
      studentGroups,
    };
  },
};
</script>
