<template>
  <el-skeleton :count="15" animated class="flex flex-wrap gap-5 w-full">
    <template #template>
      <div class="bg-white p-5 pb-2 rounded-[10px]">
        <el-skeleton-item
          variant="image"
          class="h-[200px] w-[200px] rounded-lg"
        />
        <el-skeleton-item class="w-full mt-2" />
      </div>
    </template>
  </el-skeleton>
</template>
