<template>
  <main>
    <calendar-component />
  </main>
</template>

<script>
import CalendarComponent from "@/components/CalendarComponent";
export default {
  components: {
    CalendarComponent,
  },
};
</script>
