<template>
  <div class="exam-component">
    <h2 style="text-align: left; width: 100%">
      {{ $t("exam-results.exam-title") }}
    </h2>
    <el-tabs v-model="activeExamTab" class="demo-tabs">
      <el-tab-pane
        v-for="(exam, i) in examResult"
        :name="exam?.module"
        :label="exam.module + ' ' + $t('main.module')"
        :key="i"
        ><div class="student-exam-result-wrapper">
          <div
            class="student-exam-result-box"
            v-for="(theme, i) in exam?.themes"
            :key="theme.id"
          >
            <div class="student-exam-result-box-header">
              <div class="exam-title-wrapper">
                <p class="number">{{ i + 1 }}</p>
                <p class="title">{{ theme.title }}</p>
              </div>
              <div class="student-exam-result-score">
                <span>{{ $t("exam-results.result") }}</span>
                <div class="line"></div>
                <p class="mark">
                  {{ theme.mark || "-" }}
                </p>
              </div>
            </div>
            <div class="student-exam-result-body">
              <h3 class="feedback-title">
                {{ $t("exam-results.feedback") }}
              </h3>
              <p>{{ theme.feedback }}</p>
            </div>
          </div>
          <div class="student-exam-result-total" v-if="exam?.themes.length">
            <h3>{{ $t("exam-results.exam-overall") }}</h3>
            <p
              :class="{
                maximum: exam.total_mark >= 90,
                medium: exam.total_mark >= 70 && exam.total_mark < 90,
                minimum: exam.total_mark >= 60 && exam.total_mark < 70,
                fail: exam.total_mark < 60,
              }"
            >
              {{ exam.total_mark || "-" }}
            </p>
          </div>
          <h3 v-else>{{ $t("exam-results.exam-data") }}</h3>
        </div></el-tab-pane
      >
    </el-tabs>
  </div>
</template>
<script>
import { getExamResult } from "../service/get-exam-result.js";
export default {
  props: ['group_id'],
  data() {
    return {
      centerDialogVisible: false,
      examResult: [],
      activeExamTab: null,
      module: localStorage.getItem("module"),
      course_id: localStorage.getItem("activeCourse"),
    };
  },
  methods: {
    async getExamResultData() {
      this.examResult = await getExamResult(this.group_id, this.module, this.course_id)
        .then((res) => {
          this.activeExamTab = res.data[0].module;
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
    },
  },
  mounted() {
    this.getExamResultData();
  },
};
</script>

<style lang="scss">
.exam-component {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  &-no {
    display: flex;
    background-color: red;
  }

  .el-dialog {
    width: auto;
    .el-dialog__body {
      background-color: #ffffff !important;
      border-radius: 10px;
      margin: 0 auto;
      padding: 16px;
    }
  }
  @media (max-width: 768px) {
    .el-dialog {
      width: 100%;
    }
  }
  .student-exam-result {
    &-wrapper {
      display: flex;
      border-radius: 20px;
      gap: 15px;
      flex-wrap: wrap;
      width: 768px;
      justify-content: space-between;
    }
    &-box {
      width: 48%;
      padding: 15px;
      background: #ffeee9;
      border-radius: 7px;
      margin-bottom: 25px;
      &-header {
        .exam-title-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;
          .number {
            width: 26px;
            height: 26px;
            background: #ffc7b7;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
          }
          .title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            color: #ef400f;
          }
        }
      }
    }
    @media (max-width: 768px) {
      &-box {
        width: 100%;
      }
      &-wrapper {
        width: 100%;
      }
    }
    &-score {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      padding-left: 36px;
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        font-weight: bold;
      }
      .line {
        border-bottom: 1px dashed #000000;
        flex: 1;
      }
      .mark {
        padding: 5px 10px;
        border-radius: 5px;
        background: linear-gradient(286.17deg, #22bb9c 0%, #35debc 100%);
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        color: #ffffff;
      }
    }
    &-body {
      text-align: left;
      padding-left: 36px;
      .feedback-title {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: #bdbdbd;
        white-space: wrap;
      }
    }
    &-total {
      .maximum {
        background-color: #8adf63;
      }
      .medium {
        background-color: #f8e579;
      }
      .minimum {
        background-color: rgb(90, 193, 219);
      }
      .fail {
        background-color: rgb(247, 73, 73);
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-top: 3px solid #eeeeee;
      padding-top: 20px;
      p {
        padding: 5px 10px;
        border-radius: 5px;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        color: #ffffff;
      }
    }
  }
}
</style>
