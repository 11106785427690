import axios from "axios";

const url =
  process.env.NODE_ENV === "development"
    ? "https://test.api.marsit.uz"
    : "https://api.marsit.uz";

export const getDateTime = async () => {
  try {
    return await axios.get(`${url}/now/datetime`);
  } catch (error) {
    console.log("/now/datetime", error);
  }
};
