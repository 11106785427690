<template>
  <div class="main__learning-section-right-header">
    <h2 class="main__heading course-modules__heading">
      {{ $t("main.study") }}
    </h2>
    <el-select
      v-if="activeGroup"
      v-model="activeGroup"
      popper-class="course-modules__options"
    >
      <el-option
        v-for="group in studentGroups"
        :key="group?.id"
        :label="group?.name"
        :value="group?.id"
      />
    </el-select>
    <el-select
      v-else
      v-model="activeCourse"
      popper-class="course-modules__options"
    >
      <el-option
        v-for="course in courses"
        :key="course?.id"
        :label="course?.name"
        :value="course?.id"
      />
    </el-select>
    <div class="preview">
      <router-link to="assigment" class="preview-link">Preview</router-link>
      <button
        v-if="selectedGroup"
        class="main__learning-section-right__exam-btn"
        @click="examModal = true"
      >
        <img src="@/assets/exam-icon-white.png" alt="" />Exam results
      </button>
    </div>
  </div>
  <div class="course-modules" v-show="courseModules">
    <el-tabs v-model="activeModule">
      <template v-for="courseModule in courseModules" :key="courseModule">
        <el-tab-pane :label="`${courseModule} месяц`" :name="courseModule">
          <template v-for="lesson in lessons" :key="lesson?.id">
            <the-lesson
              v-if="
                lesson?.module === courseModule && !loading && lessons?.length
              "
              :order="lesson?.order"
              :name="lesson?.title[lang]"
              :description="lesson?.about[lang] || null"
              :status="lesson?.status"
              :courseId="+selectedGroup?.course?.id || selectedCourse"
              :groupId="+selectedGroup?.id || null"
              :isExam="lesson?.isExam"
              :taskId="lesson?.id"
              :mark="lesson?.mark || 0"
            />
            <el-skeleton animated v-else>
              <template #template>
                <el-skeleton-item variant="button" />
              </template>
            </el-skeleton>
          </template>
        </el-tab-pane>
      </template>
    </el-tabs>
    <el-dialog v-model="examModal" width="auto" align-center>
      <exam-component :group_id="selectedGroup?.id" />
    </el-dialog>
  </div>
</template>

<script>
import { TheLesson } from "@/entities";
import { getCourseLessons } from "./api/get-lessons.api";
import { getCourseModules } from "./api/get-modules.api";
import { getModuleExam } from "./api/get-exam.api";
import { onBeforeMount, ref, watch } from "vue";
import { getDateTime } from "./api/get-datetime.api";
import ExamComponent from "@/components/ExamComponent.vue";
import { DateTime } from "luxon";
import { getCourseId } from "@/service/get-course-id.service";
import { useStore } from "vuex";
import "./style/modules.css";

export default {
  components: {
    TheLesson,
    ExamComponent,
  },
  setup() {
    const store = useStore();
    const activeGroup = ref(null);
    const studentGroups = ref(store.state.student.studentInfo?.groups);
    const selectedGroup = ref(null);

    const courses = ref(null);
    const activeCourse = ref(null);
    const selectedCourse = ref(null);

    watch(
      () => store.state.student.studentInfo.groups,
      (newValue) => {
        studentGroups.value = newValue;
      }
    );

    const changeactiveGroup = () => {
      if (studentGroups.value?.length) {
        activeGroup.value = studentGroups.value.find(
          (group) => group?.status === 5
        )?.id;
      }
    };

    const changeactiveCourse = () => {
      if (courses.value?.length) {
        activeCourse.value = courses.value[courses.value?.length - 1].id;
      }
    };

    const changeSelectedGroup = () => {
      if (studentGroups.value?.length) {
        selectedGroup.value = studentGroups.value.find(
          (group) => group?.id === activeGroup.value
        );
      }
    };

    const todayDateTime = ref(null);

    const saveDateTime = async () => {
      const res = await getDateTime();
      todayDateTime.value = DateTime.fromISO(res?.data).toFormat("yyyy-MM-dd");
    };

    watch(activeGroup, async (_, prev) => {
      loading.value = true;
      changeSelectedGroup();
      if (prev !== null) {
        await getModules();
        await getAllLessons().finally(() => (loading.value = false));
        await getExam();
      }
    });

    const lessons = ref(null);
    const loading = ref(false);
    const lang = localStorage.getItem("lang");
    const activeModule = ref(+localStorage.getItem("module") || 1);

    const getAllLessons = async () => {
      if (selectedGroup.value) {
        const res = await getCourseLessons(
          selectedGroup.value?.course?.id,
          selectedGroup.value?.id,
          activeModule.value
        );
        lessons.value = res?.data;
      } else {
        const res = await getCourseLessons(
          activeCourse.value,
          null,
          activeModule.value
        );
        lessons.value = res?.data;
      }
    };

    const getExam = async () => {
      if (selectedGroup.value) {
        const res = await getModuleExam(
          selectedGroup.value?.id || selectedCourse.value.id,
          activeModule.value
        );
        const tomorowExamDate = DateTime.fromISO(res?.data?.for_date)
          .plus({ days: 1 })
          .toFormat("yyyy-MM-dd");

        if (
          todayDateTime.value === res?.data?.for_date ||
          tomorowExamDate === todayDateTime.value
        ) {
          lessons.value = [
            ...lessons.value,
            {
              status: "opened",
              mark: 0,
              isExam: 1,
              about: {
                ru: "Экзамен",
                uz: "Imtihon",
              },
              ...res?.data,
            },
          ];
        }
      }
    };

    const courseModules = ref(null);

    const getModules = async () => {
      if (selectedGroup.value) {
        const res = await getCourseModules(selectedGroup.value?.course?.id);
        courseModules.value = res?.data?.modules;
      } else {
        const res = await getCourseModules(selectedCourse.value?.id);
        courseModules.value = res?.data?.modules;
      }
    };

    watch(activeGroup, async (_, oldVal) => {
      loading.value = true;
      changeSelectedGroup();
      if (oldVal !== null) {
        await getModules();
        await getAllLessons().finally(() => (loading.value = false));
      }
    });

    watch(activeCourse, async (_, oldValue) => {
      loading.value = true;
      changeSelectedCourse();
      if (oldValue !== null) {
        await getModules();
        await getAllLessons().finally(() => (loading.value = false));
      }
    });

    const getCourses = async () => {
      courses.value = await getCourseId().then((res) => res.data);
    };

    const changeSelectedCourse = () => {
      selectedCourse.value = courses.value?.find(
        (course) => course?.id === activeCourse.value
      );
    };

    onBeforeMount(async () => {
      await store.dispatch("getMe");
      localStorage.setItem("module", activeModule.value);
      loading.value = true;
      changeactiveGroup();
      await getCourses();
      changeactiveCourse();
      changeSelectedGroup();
      changeSelectedCourse();
      await saveDateTime();

      await getModules();
      await getAllLessons().finally(() => (loading.value = false));
      await getExam();
    });

    watch(activeModule, async () => {
      loading.value = true;
      localStorage.setItem("module", activeModule.value);
      await getAllLessons().finally(() => (loading.value = false));
      await getExam();
    });

    const examModal = ref(false);

    return {
      lessons,
      courseModules,
      loading,
      lang,
      activeModule,
      activeGroup,
      studentGroups,
      examModal,
      selectedGroup,
      courses,
      activeCourse,
    };
  },
};
</script>
