import api from "@/apiConfig/api";

export const getCourseLessons = async (course_id, group_id, module) => {
  try {
    return await api.get("/course_elements", {
      params: {
        course_id,
        group_id,
        module
      },
    });
  } catch (error) {
    console.log("error on endpoint getCourseModules:", error);
  }
};
