<template>
  <div class="book-tutor-modal" :style="{'display': freemiumLimit ? 'none' : 'block'}">
    <div class="book-tutor-modal__header">
      <h3 class="book-tutor-modal__header__heading">
        {{ $t("main.booking.heading") }}
      </h3>
      <button class="book-tutor-modal__header__btn" @click="closeModal">
        <img src="@/assets/close-icon.png" alt="close icon" />
      </button>
    </div>
    <div class="book-tutor-modal__body">
      <div class="tutor-list-collapse">
        <el-collapse v-model="tutorCollapseVal">
          <el-collapse-item>
            <template #title>
              <div class="tutor-list-collapse-title">
                <img
                  v-if="bookingData.selectedTutorImage"
                  class="tutor-img"
                  :src="mediaLink + bookingData.selectedTutorImage"
                  alt="tutor-image"
                />
                <img
                  v-else
                  class="tutor-img"
                  src="@/assets/default-user-img.jpg"
                  alt="tutor image"
                />
                <div>
                  <p class="tutor-name">
                    {{ bookingData.seletedTutorName || "Tutor" }}
                  </p>
                  <p class="tutor-position">
                    <span
                      v-for="pos in bookingData.selectedTutorPosition"
                      :key="pos"
                      >{{ pos + " " }}
                    </span>
                  </p>
                </div>
              </div>
            </template>
            <div class="tutors">
              <div
                class="tutor-info"
                v-for="tutor in tutorsList"
                :key="tutor.id"
                @click="selectTutorMethod(tutor)"
              >
                <img
                  v-if="tutor?.profile?.avatar"
                  :src="mediaLink + tutor.profile.avatar"
                  alt="tutor-img"
                  class="tutor-info-img"
                />
                <img
                  v-else
                  class="tutor-info-img"
                  src="@/assets/default-user-img.jpg"
                  alt=""
                />
                <p class="tutor-info-name">
                  {{ tutor.first_name }}
                  {{ tutor.last_name }}
                </p>
                <p
                  class="tutor-info-position"
                  v-for="(pos, i) in tutor.position"
                  :key="i"
                >
                  {{ pos }}
                </p>
                <el-icon
                  class="selected-tutor-icon"
                  size="20"
                  color="#EF400F"
                  v-if="tutor.id == bookingData.selectedTutor"
                  ><SuccessFilled
                /></el-icon>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div class="check-tags" v-if="bookingData.selectedTutor">
        <el-check-tag
          :checked="bookingData.selectedDate === day.date"
          v-for="day in weekDays"
          :key="day.date"
          @click="
            (bookingData.selectedDate = day.date), getSlotsMethod(day.date)
          "
        >
          <p class="check-tag-week">
            {{ Object.keys(day)[1].slice(0, 3) }}
          </p>
          <p class="check-tag-date">
            {{ DateTime.fromISO(day.date).toFormat("dd/MM") }}
          </p>
        </el-check-tag>
      </div>
      <div
        class="book-tutor-modal__body-slot-tags"
        v-if="bookingData.selectedDate"
      >
        <el-check-tag
          v-for="slot in tutorAvailableSlots"
          :key="slot?.value"
          :checked="slot.value === bookingData.selectedTime"
          :class="{
            disable: slot.is_booked,
          }"
          @click="
            !slot.is_booked
              ? (bookingData.selectedTime = slot.value)
              : (bookingData.selectedTime = null)
          "
        >
          {{ slot.label.slice(0, 5) }}
        </el-check-tag>
      </div>
    </div>
    <div
      class="book-tutor-modal__body__comment-wrapper"
      v-if="bookingData.selectedTime"
    >
      <textarea
        id="subject"
        class="book-tutor-modal__body__comment"
        :placeholder="$t('main.booking.modal.topic')"
        v-model="bookingData.lessonTopic"
      ></textarea>
    </div>
    <div class="book-tutor-modal__footer">
      <button
        class="book-tutor-modal__footer__btn"
        @click="bookLesson"
        :disabled="!bookingData.selectedTime || !bookingData.lessonTopic"
      >
        Register
      </button>
    </div>
  </div>
  <el-dialog align-center v-model="freemiumLimit" class="freemium-modal">
    <h1>{{ $t("main.booking.modal.freemium_header") }}</h1>
    <p>{{ $t("main.booking.modal.freemium_desc") }}</p>
    <button @click="closeModal">
      {{ $t("main.booking.modal.btn") }}
    </button>
  </el-dialog>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { getTutorsList } from "./api/get-tutors-list.api";
import { getTutorSlot } from "./api/get-tutor-slot.api";
import { postTutorBook } from "./api/post-tutor-book.api";
import "./style/book-tutor.css";
import { ElNotification } from "element-plus";
import { DateTime } from "luxon";
import { SuccessFilled } from "@element-plus/icons-vue";
import { mediaLink } from "@/apiConfig/mediaLink";
export default {
  emits: ["closeModal"],
  props: ["localTime", "company_id"],
  components: {
    SuccessFilled,
  },
  setup(props, { emit }) {
    const tutorsList = ref([]);

    const getTutors = async () => {
      tutorsList.value = await getTutorsList("tutor", props.company_id).then(
        (res) => res.data
      );
    };

    const bookingData = ref({
      selectedTutor: null,
      selectedDate: null,
      selectedTime: null,
      lessonTopic: null,
      seletedTutorName: "",
      selectedTutorPosition: undefined,
      selectedTutorImage: "",
    });
    const tutorCollapseVal = ref(false);

    onBeforeMount(async () => {
      await getTutors();
    });

    const tutorAvailableSlots = ref([]);

    const getSlotsMethod = async (date) => {
      tutorAvailableSlots.value = [];
      getTutorSlot(bookingData.value.selectedTutor, date).then((res) => {
        res.data.map((slot) => {
          tutorAvailableSlots.value.push({
            value: slot?.id,
            label: `${slot?.from_hour} ~ ${slot?.till_hour}`,
            is_booked: slot?.is_booked,
          });
        });
      });
    };

    const errorMsg = () => {
      ElNotification({
        message: "Not created!",
        type: "error",
      });
    };
    const bookSuccess = () => {
      ElNotification({
        message: "Created!",
        type: "success",
      });
    };

    const freemiumLimit = ref(false);

    const handleErrorResponse = (error) => {
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.detail === "You can attend up to 3 lessons !"
      ) {
        freemiumLimit.value = true;
      }
    };

    const bookLesson = async () => {
      try {
        await postTutorBook(bookingData.value);
        bookSuccess();
        emit("bookCreated");
      } catch (error) {
        if (error) {
          handleErrorResponse(error);
        } else {
          errorMsg();
        }
      }
    };

    const closeModal = () => {
      emit("closeModal");
      bookingData.value.selectedTutor = null;
      bookingData.value.selectedDate = null;
      bookingData.value.selectedTime = null;
      bookingData.value.lessonTopic = null;
      bookingData.value.seletedTutorName = "";
      bookingData.value.selectedTutorPosition = undefined;
      bookingData.value.selectedTutorImage = "";
      freemiumLimit.value = false;
    };

    const weekDays = ref([]);

    const getWeekDays = async () => {
      weekDays.value =
        bookingData.value.selectedTutor &&
        (await getTutorSlot(bookingData.value.selectedTutor)
          .then((res) => res.data)
          .catch(() => []));
    };

    const selectTutorMethod = (tutor) => {
      bookingData.value.selectedTutor = tutor.id;
      bookingData.value.seletedTutorName =
        tutor.first_name + " " + tutor.last_name;
      bookingData.value.selectedTutorPosition = tutor.position;
      bookingData.value.selectedTutorImage = tutor.profile.avatar;

      tutorCollapseVal.value = false;
      getWeekDays();
    };
    return {
      tutorsList,
      bookingData,
      bookLesson,
      closeModal,
      tutorAvailableSlots,
      weekDays,
      getSlotsMethod,
      getWeekDays,
      DateTime,
      mediaLink,
      tutorCollapseVal,
      selectTutorMethod,
      freemiumLimit,
    };
  },
};
</script>

<style></style>
