<template>
  <div class="ans-and-ques">
    <div class="h-screen fixed top-0 left-0 bg-[#00000057] w-screen">
      <div class="flex w-full h-screen items-center justify-center">
        <div class="w-[600px] bg-white rounded-xl p-5 h-auto">
          <div class="flex items-center justify-between">
            <p class="text-titleBlue font-bold text-2xl">{{ $t("survey") }}</p>
            <el-select class="!w-[80px]" v-model="lang">
              <el-option value="uz" label="Uz" />
              <el-option value="ru" label="Ru" />
            </el-select>
          </div>
          <!-- progress -->
          <div class="mt-5">
            <div class="flex gap-2 items-center">
              <div
                class="rounded-full flex-1 bg-[#FFF8ED] border border-solid border-[#FF980014] mt-2 relative max-w-full"
              >
                <div
                  class="h-[12px] bg-[#FFDC33] rounded-full relative max-w-full min-w-[28px]"
                  :style="{ width: inProgresWidth }"
                >
                  <div
                    class="w-[28px] h-[28px] rounded-full flex items-center justify-center font-bold text-white absolute -top-[8px] right-0 border-2 border-solid bg-[#FFDC33] border-[#FFD300]"
                  >
                    {{ active + 1 <= ques.length ? active + 1 : ques.length }}
                  </div>
                </div>
              </div>
              <!-- coins -->
              <div class="flex items-center gap-1 mt-1">
                <p class="text-lg font-bold text-titleBlue mt-1">
                  +{{ coins }}
                </p>
                <img src="@/assets/Coin.svg" alt="" width="20" height="20" />
              </div>
            </div>
          </div>

          <!-- active  question -->
          <div v-if="activeQues" class="mt-5">
            <p class="font-[600] text-xl text-titleBlue">
              {{ active + 1 }}.{{ activeQues?.ques[lang] }}
            </p>
            <el-alert
              v-if="activeQues?.desc"
              :title="activeQues.desc[lang]"
              show-icon
              :closable="false"
              class="my-2"
              type="warning"
            />

            <el-select v-if="activeQues?.selects" v-model="schoolSelectVal">
              <el-option
                v-for="el in activeQues.selects[lang]"
                :label="el"
                :value="el"
                :key="el"
              />
            </el-select>

            <p v-if="error === 'error'" class="my-1 text-red-500 font-bold">
              {{
                lang === "uz"
                  ? "Matnni to'gri kiritganingizga ishonch hosil qiling"
                  : "Убедитесь, что вы вводите текст правильно"
              }}
            </p>
            <input
              v-if="activeQues.type === 'text'"
              v-model="ans[activeQues.answer_key]"
              :placeholder="activeQues?.placeholders[lang]"
              class="bg-[#F6FAFD] p-2 rounded-md border border-solid border-[#007AFF26] w-full mt-2"
              :type="inpType"
              @input="validateInput"
            />

            <el-date-picker
              v-else-if="activeQues.type === 'date'"
              v-model="ans[activeQues.answer_key]"
              :placeholder="activeQues?.placeholders[lang]"
              :disabled-date="activeQues?.age_filter ? disabledDate : false"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
            <div v-else-if="activeQues.type === 'radio'" class="mt-1">
              <div
                class="flex items-center gap-1 mt-1"
                v-for="radio in activeQues.radios[lang]"
                :key="radio"
              >
                <input
                  type="radio"
                  :id="radio"
                  name="radio"
                  class="bg-[#F6FAFD] p-2 rounded-md border border-solid border-[#007AFF26] accent-primary500"
                  v-model="ans[activeQues.answer_key]"
                  :value="radio"
                />
                <label :for="radio" class="font-bold text-titleBlue mt-1">{{
                  radio
                }}</label>
              </div>
            </div>
            <div v-else-if="activeQues.type === 'checkbox'" class="mt-1">
              <div class="flex items-center gap-1 flex-wrap">
                <el-check-tag
                  :key="check"
                  :checked="ans[activeQues.answer_key].includes(check)"
                  @change="handleCheckChange(check)"
                  v-for="check in activeQues.checks[lang]"
                >
                  {{ check }}
                </el-check-tag>
              </div>
            </div>
          </div>

          <!-- next btn -->
          <div class="mt-5 text-end">
            <button
              :disabled="nextBtnDisabled || error === 'error'"
              v-if="ques.length > active"
              @click="nextQues"
              class="px-4 text-sm py-2 bg-primary500 text-white font-bold rounded-lg cursor-pointer hover:opacity-90 disabled:opacity-30"
            >
              {{ $t("parent-part.instructions.next") }}
            </button>
            <button
              @click="sendAns"
              v-else
              class="px-4 text-sm py-2 bg-primary500 text-white font-bold rounded-lg cursor-pointer hover:opacity-90 disabled:opacity-30"
            >
              {{ $t("quiz.send-btn") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import ques from "./ques.json";
import apiV2 from "@/apiConfig/apiV2";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";

const active = ref(0);
const inProgresWidth = computed(() => (active.value / ques.length) * 100 + "%");
const activeQues = computed(() => ques[active.value]);
const coins = ref(0);
const lang = ref("uz");
const store = useStore();
const schoolSelectVal = ref("Davlat maktabi");
const error = ref("");

const disabledDate = (time) => {
  const currentYear = new Date().getFullYear();
  const birthYearMin = currentYear - 19; // Foydalanuvchi 19 yoshda bo'lgan yil
  const birthYearMax = currentYear - 8; // Foydalanuvchi 7 yoshda bo'lgan yil
  return time.getFullYear() < birthYearMin || time.getFullYear() > birthYearMax;
};
const inpType = computed(() => {
  if (
    (schoolSelectVal.value === "Davlat maktabi" ||
      schoolSelectVal.value === "Государственная школа") &&
    activeQues.value?.selects
  ) {
    return "number";
  } else {
    return "text";
  }
});

const validateInput = (e) => {
  if (/(\w)\1{2,}/.test(e.target.value) && typeof e.target.value === "string") {
    error.value = "error";
  } else {
    error.value = "ok";
  }
};

const nextQues = () => {
  active.value++;
  coins.value += activeQues.value?.coin || 0;
  error.value = "ok";
};
const ans = ref({});

onMounted(() => {
  ques.forEach((q) => {
    if (q.answer_type === "number") ans.value[q.answer_key] = null;
    else if (q.answer_type === "array") ans.value[q.answer_key] = [];
    else ans.value[q.answer_key] = "";
  });
});

const isMaxSelected = ref(false);

const handleCheckChange = (check) => {
  const answerKey = activeQues.value.answer_key;
  const answerArray = ans.value[answerKey];

  // Check if the item is already selected
  if (answerArray.includes(check)) {
    // Remove the check if already selected
    ans.value[answerKey] = answerArray.filter((item) => item !== check);
    isMaxSelected.value = false; // Reset the max flag
  } else if (answerArray.length < 3) {
    // Add the check if not selected and less than 3 selected
    ans.value[answerKey].push(check);
    isMaxSelected.value = false;
  } else {
    // Show a message if max selection reached
    isMaxSelected.value = true;
  }
};

const nextBtnDisabled = computed(() => {
  if (activeQues.value.type === "checkbox")
    return ans.value[activeQues.value.answer_key]?.length !== 3;
  return !ans.value[activeQues.value.answer_key]?.length;
});

const sendAns = async () => {
  apiV2
    .post("/reports/survey", ans.value)
    .then(() => {
      ElMessage.success("+15 coin");
      store.dispatch("getMe");
    })
    .catch(() => {
      ElMessage.error("Failed");
    });
};
</script>
<style lang="scss">
.ans-and-ques {
  .el-check-tag {
    &.is-checked {
      background-color: #ef400f; // Your primary-500 color
      color: white; // Adjust text color
      border-color: #ef400f;
    }
  }
}
</style>
