<template>
  <div
    :class="[
      'hw-send-modal',
      {
        'hw-send-modal--mini': sendingStatus.length,
      },
    ]"
  >
    <el-tabs v-model="activeTab" class="demo-tabs" v-if="!sendingStatus.length">
      <el-tab-pane
        :label="$t('modal.heading.file')"
        name="file"
        v-if="+lesson !== 1"
      >
        <el-upload
          class="hw-send-modal__dragndrop"
          drag
          :show-file-list="false"
          v-model:file-list="hwData.fileList"
          :auto-upload="false"
          accept="video/*, image/*, .zip, .rar, .js, .html, .py, .sb3, .css, .pdf, .psd, .ai, .eps"
          v-if="!hwData?.fileList.length"
        >
          <div class="el-upload__text">
            Перетащите или <br />
            <em>Загрузите локальный файл</em>
          </div>
        </el-upload>
        <div v-else class="hw-send-modal__file">
          <div class="hw-send-modal__file-left">
            <img src="@/assets/file-icon.svg" alt="file logo" />
          </div>
          <div class="hw-send-modal__file-center">
            <h4 class="hw-send-modal__file__heading">
              {{ $t("modal.file-check.file") }}:
            </h4>
            <p class="hw-send-modal__file__name">
              {{ hwData?.fileList[0]?.name || "Нет файлов" }}
            </p>
          </div>
          <div class="hw-send-modal__file-right">
            <button
              class="hw-send-modal__file__rm-btn"
              @click="removeFile(hwData?.fileList[0]?.name)"
            >
              <img src="@/assets/delete-btn.svg" alt="delete btn" />
            </button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('modal.heading.file')" name="file" v-else>
        <h1 v-if="!examThemes?.length">{{ $t("modal.hasnt_exam.there_is_no_exam") }}</h1>
        <template v-for="exam in examThemes" :key="exam?.id">
          <h4>{{ exam?.title }}</h4>
          <el-upload
            class="hw-send-modal__dragndrop"
            drag
            :show-file-list="false"
            v-model:file-list="examData[exam?.id].file"
            :auto-upload="false"
            accept="video/*, image/*, .zip, .rar, .js, .html, .py, .sb3, .css, .pdf, .psd, .ai, .eps"
            v-if="!examData[exam?.id].file.length"
          >
            <div class="el-upload__text">
              Перетащите или <br />
              <em>Загрузите локальный файл</em>
            </div>
          </el-upload>
          <div v-else class="hw-send-modal__file">
            <div class="hw-send-modal__file-left">
              <img src="@/assets/file-icon.svg" alt="file logo" />
            </div>
            <div class="hw-send-modal__file-center">
              <h4 class="hw-send-modal__file__heading">
                {{ $t("modal.file-check.file") }}:
              </h4>
              <p class="hw-send-modal__file__name">
                {{ examData[exam?.id].file[0]?.name || "Нет файлов" }}
              </p>
            </div>
            <div class="hw-send-modal__file-right">
              <button
                class="hw-send-modal__file__rm-btn"
                @click="
                  removeExamFile(examData[exam?.id].file[0]?.name, exam?.id)
                "
              >
                <img src="@/assets/delete-btn.svg" alt="delete btn" />
              </button>
            </div>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane :label="$t('modal.heading.link')" name="link">
        <input
          type="text"
          v-model="hwData.link"
          class="hw-send-modal__link-inp"
          :placeholder="$t('modal.upload.link')"
          v-if="+lesson !== 1"
        />
        <div v-else>
          <h1 v-if="!examThemes?.length">{{ $t("modal.hasnt_exam.there_is_no_exam") }}</h1>
          <template v-for="exam in examThemes" :key="exam?.id">
            <h4>{{ exam?.title }}</h4>
            <input
              type="text"
              v-model="examData[exam?.id].link"
              class="hw-send-modal__link-inp"
              :placeholder="$t('modal.upload.link')"
            />
          </template>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('modal.heading.comment')"
        name="comment"
        v-if="+lesson !== 1"
      >
        <textarea
          class="hw-send-modal__comment"
          :placeholder="$t('modal.upload.comment')"
          type="text"
          v-model="hwData.comment"
          style="resize: none"
        ></textarea>
      </el-tab-pane>
    </el-tabs>
    <ModalResult
      v-else-if="sendingStatus === 'loading'"
      :heading="$t('modal.loading.heading')"
      :title="$t('modal.loading.title')"
      :resultStatus="sendingStatus"
    />
    <ModalResult
      v-else-if="sendingStatus === 'success'"
      :heading="$t('modal.uploaded.heading')"
      :title="$t('modal.uploaded.title')"
      :resultStatus="sendingStatus"
    />
    <ModalResult
      v-else-if="sendingStatus === 'fail'"
      :heading="$t('modal.fail.heading')"
      :title="$t('modal.fail.title')"
      :resultStatus="sendingStatus"
    />
    <div class="hw-send-modal__btn-wrapper">
      <button
        class="hw-send-modal__btn hw-send-modal__btn--upload"
        :disabled="!canSendFile"
        @click="!sendingStatus.length ? sendData() : reloadPage()"
        v-if="!sendingStatus.length || sendingStatus !== 'loading'"
      >
        {{
          !sendingStatus.length
            ? $t("modal.upload.upload_btn")
            : $t("modal.uploaded.btn")
        }}
      </button>
      <button
        class="hw-send-modal__btn hw-send-modal__btn--cancel"
        @click="closeModal"
        v-if="!sendingStatus.length || sendingStatus === 'loading'"
      >
        {{ $t("modal.upload.cancel_btn") }}
      </button>
    </div>
  </div>
</template>
<script>
import { computed, ref, toRefs, watch } from "vue";
import { postHWFiles } from "./api/post-hw.api";
import { ModalResult } from "@/shared/UI";
import "./style/hw-send-modal.css";
import { useRoute } from "vue-router";
import { postExamFiles } from "./api/post-exam.api";

export default {
  emits: ["closeModal"],
  props: {
    hasSentAnswer: {
      type: Boolean,
      required: true,
    },
    examThemes: {
      type: Array,
      required: false,
    },
  },
  components: {
    ModalResult,
  },
  setup(props, { emit }) {
    const route = useRoute();

    const { task, group, lesson } = route.query;

    const activeTab = ref("file");

    const fileList = ref([]);

    const hwData = ref({
      fileList: [],
      link: "",
      comment: "",
    });

    const examData = ref({});

    const getExamThemes = () => {
      props.examThemes.forEach((exam) => {
        examData.value[exam?.id] = {
          file: [],
          link: "",
        };
      });
    };

    if (+lesson === 1) {
      getExamThemes();
    }

    const closeModal = () => {
      emit("closeModal");
      hwData.value = {
        fileList: [],
        link: "",
        comment: "",
      };
      sendingStatus.value = "";
    };

    const { link } = toRefs(hwData.value);

    watch(link, (val) => {
      if (!val.startsWith("http")) {
        link.value = `https://${val}`;
      }
    });

    const removeFile = (fileName) => {
      hwData.value.fileList = hwData.value.fileList.filter(
        (file) => file.name !== fileName
      );
    };
    const removeExamFile = (fileName, examID) => {
      examData.value[examID].file = examData.value[examID].file.filter(
        (file) => file.name !== fileName
      );
    };

    const canSendFile = computed(() => {
      if (+lesson === 1) {
        return true;
      } else {
        return !!hwData.value.fileList.length || !!hwData.value.link.length;
      }
    });

    const sendingStatus = ref("");

    const sendHW = async () => {
      if (canSendFile.value) {
        sendingStatus.value = "loading";
        let formData = new FormData();
        formData.set("file", hwData.value.fileList[0]?.raw || "");
        formData.set("link", hwData.value.link);
        formData.set("comments", hwData.value.comment);
        if (group && group !== undefined && group !== null) {
          formData.set("group_id", +group);
        }
        formData.set("element_id", +task);
        await postHWFiles(!!props.hasSentAnswer, formData)
          .then(() => {
            sendingStatus.value = "success";
          })
          .catch(() => {
            sendingStatus.value = "fail";
          });
      }
    };

    const sendExam = async () => {
      for (const examID in examData.value) {
        sendingStatus.value = "loading";
        let formData = new FormData();
        formData.set("file", examData.value[examID].file[0]?.raw || "");
        const examThemeData = {
          theme_id: examID,
          group_id: group,
          link: examData.value[examID].link,
        };
        await postExamFiles(examThemeData, formData)
          .then(() => {
            sendingStatus.value = "success";
          })
          .catch(() => {
            sendingStatus.value = "fail";
          });
      }
    };

    const sendData = async () => {
      if (+lesson === 0) {
        await sendHW();
      } else {
        await sendExam();
      }
    };

    const reloadPage = () => {
      window.location.reload();
    };

    return {
      activeTab,
      closeModal,
      fileList,
      hwData,
      removeFile,
      canSendFile,
      sendHW,
      sendingStatus,
      reloadPage,
      lesson,
      examData,
      removeExamFile,
      sendExam,
      sendData,
    };
  },
};
</script>