<template>
  <div class="the-course">
    <div class="the-course__image">
      <img v-if="course?.image" :src="mediaLink + course?.image" alt="" />
      <div
        v-else
        class="h-[159px] rounded-[10px] bg-[#f6fafd] flex items-center justify-center"
      >
        <p class="font-bold text-[#00A9F1]">{{ course?.name }}</p>
      </div>
    </div>
    <div class="the-course__body">
      <div class="the-course__body-tag">
        <p v-if="course?.type === 'free'" class="free tag">Free</p>
        <p v-else class="current tag">Current</p>
      </div>
      <div class="the-course__body-text">
        <h2 class="the-course__body-text-title">{{ course?.name }}</h2>
        <p class="the-course__body-text-desc">
          {{ course?.group }}
        </p>
      </div>
    </div>
    <div class="the-course__footer">
      <button @click="handleButtonClick">
        Check
        <img src="@/assets/arrow-right-blue.svg" alt="" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import "./style/the-course.scss";
import { mediaLink } from "@/apiConfig/mediaLink";
import { useStore } from "vuex";

const props = defineProps(["course", "reload"]);
const router = useRouter();
const store = useStore();

function handleButtonClick() {
  store.dispatch("setGroupId", props.course?.group_id);
  store.dispatch("setCourseId", props.course?.id);
  store.dispatch("setGroupName", props.course?.group);

  localStorage.setItem("groupId", props.course?.group_id);
  localStorage.setItem("courseId", props.course?.id);
  localStorage.setItem("groupName", props.course?.group);

  router.push(`/learning/${props.course?.id}`);
}
</script>
