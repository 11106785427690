<template>
  <el-skeleton animated class="w-[340px]" :count="8">
    <template #template>
      <div
        class="flex items-center gap-2 bg-white mb-2 rounded-[10px] p-2 w-full"
      >
        <el-skeleton-item
          variant="image"
          class="w-[50px] h-[50px] rounded-full"
        />
        <div class="flex-1">
          <el-skeleton-item class="w-[150px]" />
          <br />
          <el-skeleton-item class="w-full" />
        </div>
      </div>
    </template>
  </el-skeleton>
</template>
