<template>
  <div class="bg-white p-3 rounded-[10px] mt-5 student-bedges">
    <StudentBadgesSkeleton v-if="loading" />
    <div v-else-if="inProgres" class="flex gap-2 items-center">
      <img
        :src="mediaLink + inProgres?.taskbadge?.image"
        alt="bedge"
        class="w-[80px] h-[80px] object-contain"
      />

      <div>
        <div class="flex items-center justify-between w-full">
          <p class="text-titleBlue font-bold text-lg">
            {{ inProgres?.taskbadge?.title }}
          </p>
          <button
            class="font-[600] text-lightBlue text-sm"
            @click="isOpenDialog = true"
          >
            {{ $t("in-detail") }}
          </button>
        </div>
        <p class="text-grayScale500 text-sm leading-[16px] mt-1">
          {{
            lang === "uz"
              ? `${inProgres?.xp_from}ta dars qoldirmasdan darslarda qatnashish`
              : ` посещать занятия, не пропуская ${inProgres?.xp_from} занятий`
          }}
        </p>
        <div
          class="rounded-full bg-[#FFF8ED] border border-solid border-[#FF980014] mt-2"
        >
          <div
            class="h-[12px] bg-[#FFDC33] rounded-full relative max-w-full"
            :style="{ width: inProgresWidth }"
          >
            <div
              class="w-[28px] h-[28px] rounded-full flex items-center justify-center font-bold text-white absolute -top-[8px] right-0 border-2 border-solid bg-[#FFDC33] border-[#FFD300]"
            >
              {{ inProgres.current_streak }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="text-grayScale600 text-sm font-bold">
      No badges in progress
    </p>

    <el-dialog
      v-model="isOpenDialog"
      class="!w-[500px] rounded-[10px] relative"
      title="Badge"
    >
      <div class="">
        <p class="text-titleBlue text-lg font-bold">
          {{ $t("your-achievements") }}
        </p>
        <div class="mt-2 border-b-[1px] border-solid border-grayScale200 pb-5">
          <div class="flex gap-5 items-start flex-wrap">
            <div
              class="text-center"
              v-for="badge in earnedBadges"
              :key="badge.id"
            >
              <el-popover
                ref="popoverRef"
                trigger="hover"
                popper-style="width: unset"
                placement="right"
              >
                <template #reference>
                  <img
                    v-popover="popoverRef"
                    :src="mediaLink + badge.taskbadge?.image"
                    alt="badge"
                    class="w-[80px] h-[80px] object-contain mx-auto"
                  />
                </template>
                <div v-if="lang === 'ru'">
                  <p class="text-lg font-bold text-titleBlue">
                    {{ badge.title }}
                  </p>
                  <p class="text-grayScale600 font-bold text-sm">
                    За посещение {{ badge.xp_from }} уроков без пропусков
                  </p>
                </div>
                <div v-else>
                  <p class="text-lg font-bold text-titleBlue">
                    {{ badge.taskbadge.title }}
                  </p>
                  <p class="text-grayScale600 font-bold text-sm">
                    {{ badge.xp_from }} ta darsni qoldirmasdan qatnashgani uchun
                  </p>
                </div>
              </el-popover>
              <span class="text-titleBlue text-xs font-[500]">
                {{ badge?.taskbadge?.title }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <p class="text-titleBlue text-lg font-bold">
          {{ $t("next-achievements") }}
        </p>
        <div class="mt-2 pb-5">
          <div class="flex gap-5 flex-wrap">
            <div
              class="text-center"
              v-for="badge in toEarnBadge"
              :key="badge.id"
            >
              <el-popover
                ref="popoverRef"
                trigger="hover"
                popper-style="width: unset"
                placement="right"
              >
                <template #reference>
                  <img
                    v-popover="popoverRef"
                    :src="mediaLink + badge.taskbadge?.image"
                    alt="badge"
                    class="w-[80px] h-[80px] object-contain mx-auto opacity-30 hover:opacity-100"
                  />
                </template>
                <div v-if="lang === 'ru'">
                  <p class="text-lg font-bold text-titleBlue">
                    {{ badge.title }}
                  </p>
                  <p class="text-grayScale600 font-bold text-sm">
                    За посещение {{ badge.xp_from }} уроков без пропусков
                  </p>
                </div>
                <div v-else>
                  <p class="text-lg font-bold text-titleBlue">
                    {{ badge.taskbadge.title }}
                  </p>
                  <p class="text-grayScale600 font-bold text-sm">
                    {{ badge.xp_from }} ta darsni qoldirmasdan qatnashgani uchun
                  </p>
                </div>
              </el-popover>

              <span class="text-lightBlue font-[600]">{{
                badge.taskbadge?.title
              }}</span>
              <div
                class="w-[63px] h-[4px] rounded-full bg-[#F6FAFD] border-[0.4px] border-solid border-[#335EF714] mx-auto"
              >
                <div
                  class="h-[4px] rounded-full bg-lightBlue max-w-full"
                  :style="{
                    width: getLineWidth(badge.current_streak, badge.xp_from),
                  }"
                ></div>
              </div>
              <p class="text-xs font-bold text-grayScale600 mt-1">
                {{ badge.current_streak }}/{{ badge.xp_from }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, defineProps, watch } from "vue";
import StudentBadgesSkeleton from "./StudentBadgesSkeleton.vue";
import { getStdBadges } from "./api";
import { mediaLink } from "@/apiConfig/mediaLink";

const props = defineProps(["id"]);

const isOpenDialog = ref(false);
const loading = ref(false);
const badges = ref([]);
const lang = localStorage.getItem("lang");
const popoverRef = ref();

const inProgres = computed(() => badges.value.find((b) => b.in_progress));
const inProgresWidth = computed(() => {
  return (
    (inProgres.value?.current_streak / inProgres.value?.xp_from) * 100 + "%"
  );
});

const earnedBadges = computed(() =>
  badges.value.filter((b) => !b?.in_progress)
);
const toEarnBadge = computed(() => badges.value.filter((b) => b?.in_progress));
const getLineWidth = (current, till) => {
  return (current / till) * 100 + "%";
};

const getStdBadgesFunc = async (id = null) => {
  loading.value = true;

  badges.value = await getStdBadges(id)
    .then((res) => res.data)
    .catch(() => []);

  loading.value = false;
};

onMounted(async () => {
  getStdBadgesFunc(props.id);
});

watch(
  () => props.id,
  () => {
    getStdBadgesFunc(props.id);
  }
);
</script>
<style lang="scss">
.student-bedges {
  .el-dialog {
    &__title {
      font-size: 30px;
      font-weight: 600;
      color: #0e0d5d;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 0;
      border-bottom: 1px solid #eee;
    }
    &__headerbtn {
      width: auto;
      height: auto;
      position: inherit;
      i {
        font-size: 24px;
        color: #0e0d5d !important;
      }
    }
  }
}
</style>
