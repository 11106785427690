import api from "@/apiConfig/api";

export const getBookInfo = async (from_date, till_date, student_id) => {
  return await api.get("course_elements/extra_lessons", {
    params: {
      from_date,
      till_date,
      student_id,
    },
  });
};
