import api from "@/apiConfig/api";

export const getModuleExam = async (group_id, module) => {
  try {
    return await api.get(`/courses/group_exam`, {
      params: {
        group_id,
        module,
      },
    });
  } catch (error) {
    console.log("/courses/${course_id}/modules:", error);
  }
};
