<template>
  <the-header />
  <router-view />
  <footer-mob v-if="this.$route.name != 'parent-main'" />
  <footer-desc v-else />
</template>

<script>
// import TheHeader from "@/components/TheHeader.vue";
import FooterMob from "@/components/FooterMob.vue";
import FooterDesc from "@/components/FooterDesk.vue";
import { TheHeader } from "@/widgets";

export default {
  components: {
    TheHeader,
    FooterMob,
    FooterDesc,
  },
};
</script>

<style></style>
